import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { Startup } from '@app/Startup.tsx'

import '@app/index.css'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Startup />
  </StrictMode>
)
