import { lazy, ReactElement } from 'react'

const VisitedPage = lazy(() => import('@pages/Visited'))
const TelegramPage = lazy(() => import('@pages/Telegram'))
const TelegramWithEnvPage = lazy(() => import('@pages/TelegramWithEnv'))
const AnalyticalSecondPage = lazy(() => import('@pages/AnalyticalSecond'))

type Route = {
  caption: string
  to: string
  element: ReactElement
}

type Routes = Array<Route>

export const publicRoutes: Routes = [
  {
    caption: 'Visited',
    to: '/analytical-space/visited/:id',
    element: <VisitedPage />,
  },
  {
    caption: 'Telegram',
    to: '/analytical-space/telegram/:id',
    element: <TelegramPage />,
  },
  {
    caption: 'SecondVisit',
    to: '/analytical-space/second-visit',
    element: <AnalyticalSecondPage />,
  },
  {
    caption: 'TelegramWithEnv',
    to: '/',
    element: <TelegramWithEnvPage />,
  },
]
