import { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { publicRoutes } from '@app/routes'

export const AppRoutes = () => (
  <BrowserRouter>
    <Suspense>
      <Routes>
        {publicRoutes.map(({ caption, to, element }) => (
          <Route key={caption} element={element} path={to} />
        ))}
      </Routes>
    </Suspense>
  </BrowserRouter>
)
